.outer {
  width: 100%;
  height: 18.75rem;
  /* display: flex; */
}

.blank {
    visibility: hidden;
}

.metro_stop {
  height: 3.5rem;
}

.active,
.undefined {
  height: 3.5rem;
  width: 3.5rem;
}

.active {
  border-radius: 50%;
  border-width: 0.5rem;
  transform: scale(1.38);
  border-style: solid;
  background-color: #e6e7e8;
}

.visual {
  background-color: #afad25;
}

.events {
  background-color: #ff622d;
}

.ideas {
    background-color: #f9ed32;
}
.writing {
    background-color: #12058f;
}

.visual .active {
    border-color: #afad25;
}

.events .active {
    border-color: #ff622d;
}

.ideas .active {
    border-color: #f9ed32;
}

.writing .active {
    border-color: #12058f;
}

.last
 {
    height: 3.5rem;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

@media screen and (max-width: 1020px) {
  .outer {
    height: 10rem;
  }

  .metro_stop,
  .last {
    height: 1.2rem;
  }
  
  .active,
  .undefined {
    height: 1.2rem;
    width: 1.2rem;
  }

  .active {
    border-width: 0.165rem;
  }
}