.error {
    display: flex;
    flex-flow: column;
    text-align: center;
    padding: 2rem 4rem;
}

.error h1 {
    font-size: 2.4rem;
}

.error p {
    font-size: 1.6rem;
}

.error p a {
    text-decoration: underline;
    cursor: pointer;
}