.line {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-flow: column;
  text-align: center;
  width: 3.5rem;
  margin: 5rem 1.75rem 0 1.75rem;
}

.line:first-child {
  text-align: right;
}

.line:last-child {
  text-align: left;
}

.label {
  width: 25rem;
  margin-right: 5rem;
  margin-left: 5rem;
}

@media screen and (max-width: 1020px) {
  .line {
    width: 1.2rem;
    margin: 3rem 0.5rem 0 0.5rem;
  }

  .label {
    width: 10rem;
    font-size: 0.8rem;
  }
}
