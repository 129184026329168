.nav {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.track {
  display: inline;
}

.gallery-right,
.gallery-down {
  cursor: pointer;
  display: none;
}

.gallery-right p,
.gallery-down p {
  margin-left: 0;
  margin-right: 1rem;
}

@media screen and (max-width: 1020px) and (orientation: portrait) {
  .track {
    display: none;
  }

  .gallery-right {
    display: flex;
  }
}

@media screen and (max-height: 760px) and (orientation: landscape) {
  .gallery-down {
    display: flex;
  }
}