.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  text-align: center;
  margin: 5rem auto;
}

@media screen and (max-width: 1020px) {
  .contact {
    font-size: 1rem;
    margin: 2rem;
  }
}

@media screen and (max-width: 720px) {
  .contact {
    font-size: 0.875rem;
  }
}
