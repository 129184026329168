/* TODO: #4 ALL STYLES TO BE REMADE HERE */

.galleryItem {
  height: 100%;
  width: fit-content;
  margin: 0 4rem;
  display: flex;
  /* align-items: center; */
  flex-flow: column;
  justify-content: center;
  scroll-snap-align: center;
}

/* we don't work with vh anymore, change this */
.galleryItem-vertical {
  width: 100%;
  max-height: calc(100vh - 12rem);
  margin: 2rem 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.image {
  max-height: 90%;
  position: relative;
}

.galleryItem-vertical .image {
  max-width: 100%;
}

.image:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.description {
  height: 10%;
  padding: 1rem 0;
  display: flex;
  /* align-items: center; */
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 1020px) {
  .galleryItem {
    margin: 0 2rem;
  }

  .image {
    max-width: 80vw;
    max-height: 80%;
  }

  .description {
    height: auto;
    text-align: center;
  }
}

@media screen and (max-height: 760px) and (orientation: landscape) {
  .galleryItem {
    scroll-snap-align: none;
  }
}
