.button {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  /* flex-flow: row;
  flex-wrap: wrap;
  width: fit-content; */
  align-items: center;
  color: white;
}

.button p {
  font-size: 1.4rem;
  margin-left: 1rem;
  text-align: left;
}

.icon {
  font-size: 3.6rem;
}

@media screen and (max-width: 1420px) {
  .button p {
    font-size: 1rem;
  }

  .icon {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 350px) and (orientation: portrait) {
  .button p {
    font-size: 0.84rem;
  }

  .icon {
    font-size: 1.6rem;
  }
}

@media screen and (max-height: 600px) and (orientation: portrait) {
  .button p {
    font-size: 0.84rem;
  }

  .icon {
    font-size: 1.6rem;
  }
}
