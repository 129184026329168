.right {
  width: 70%;
  /* padding: 5rem 2rem; */
  padding: 5rem 2rem 2rem 2rem;
  overflow-y: scroll;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column;
  text-align: left;
}

.right img,
.right video {
  margin: 1rem 0;
}

.right img:before,
.right video:before {
  content: "";
  display: block;
  padding-right: 100%;
  padding-bottom: 100%;
}

@media screen and (max-width: 1020px) and (orientation: portrait) {
  .right {
    width: 100%;
    padding: 4rem 0;
  }

  .right p,
  .right h3,
  .right img,
  .right video {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media screen and (max-height: 760px) and (orientation: landscape) {
  .right {
    width: 100%;
    padding: 3rem 0 1rem 0;
  }

  .right p,
  .right h3,
  .right img,
  .right video {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
