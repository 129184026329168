.description-button {
  position: fixed;
  top: 2rem;
  right: 2rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  z-index: 50;
  background-color: black;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.description-button div {
  height: 3px;
  border: 1px solid #e6e7e8;
  border-radius: 2px;
  margin: 2px 0;
  width: 60%;
  background-color: #e6e7e8;
}
