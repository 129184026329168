.icon {
    display: flex;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    background-color: black;
}

.icon a {
    display: block;
    margin: auto;
}

.icon svg {
    color: #f1f2f2;
    width: 3rem;
    height: 3rem;
}

@media screen and (max-width: 1020px) {
    .icon {
        width: 4rem;
        height: 4rem;
    }

    .icon svg {
        width: 2rem;
        height: 2rem;
    }
}