.left {
  width: 30%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 2rem 4rem;
  color: white;
  overflow: hidden;
}

.left h2 {
  margin-block-end: 2rem;
  line-height: 95%;
}

.left p {
  font-size: 1.2rem;
  line-height: 150%;
  text-align: justify;
}

@media screen and (max-width: 2200px) {
  .left p {
    font-size: 1.12rem;
  }
}

@media screen and (max-width: 1900px) {
  .left p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1420px) {
  .left {
    padding: 0 2rem 2rem 2rem;
  }

  .left h2 {
    margin-block-end: 1.6rem;
    font-size: 2rem;
  }

  .left p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1020px) and (orientation: portrait) {
  .left {
    position: fixed;
    left: 0;
    z-index: 50;
    width: 100%;
    transition: all 300ms ease-in;
  }

  .inactive {
    left: -100vw;
  }

  .clicked-back {
    left: 100vw;
  }

  .left h2 {
    font-size: 2.4rem;
    margin-block-end: 2rem;
  }

  .left p {
    font-size: 0.9rem;
  }
}

@media screen and (max-height: 760px) and (orientation: landscape) {
  .left {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    transition: all 300ms ease-in;
  }

  .inactive {
    top: -100vh;
  }

  .clicked-back {
    left: 100vw;
  }

  .left h2 {
    font-size: 2.4rem;
    margin-block-end: 2rem;
  }

  .left p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 350px) and (orientation: portrait) {
  .left h2 {
    font-size: 1.6rem;
    margin-block-end: 1rem;
  }

  .left p {
    font-size: 0.72rem;
  }
}

@media screen and (max-height: 600px) and (orientation: portrait) {
  .left h2 {
    font-size: 1.6rem;
    margin-block-end: 1rem;
  }

  .left p {
    font-size: 0.72rem;
  }
}
