.about {
  flex-direction: column;
  width: 100%;
}

.about h2 {
  font-size: 6.25rem;
}

.about p {
  max-width: 42.25rem;
  margin: 5rem auto;
  text-align: left;
}

.avatar {
  width: 29.75rem;
  height: 29.75rem;
  border-radius: 50%;
  margin: auto;
  /* background: url('https://scontent-cdg2-1.xx.fbcdn.net/v/t1.6435-9/39027427_2116168425084781_5322251828853735424_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=28ToGgpEXEEAX_0mIVq&_nc_ht=scontent-cdg2-1.xx&oh=00_AT_oDA8uCR1X8WWBIyKMZYbfNRNM0u3y-Q3fNdlZ2ARE7w&oe=62E1D4B6');
    background-position: center;
    background-size: 140%; */
}

.avatar img {
  width: 100%;
}

@media screen and (max-width: 1020px) {
  .about h2 {
    font-size: 4rem;
  }

  .about p {
    max-width: 100%;
    padding: 0 2rem;
    font-size: 1.2rem;
  }

  .avatar {
    width: calc(60% - 4rem);
    height: auto;
    margin: 2rem auto;
  }
}

@media screen and (max-width: 560px) {
  .avatar {
    width: calc(100% - 4rem);
  }
}
