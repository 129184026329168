.customer-logo {
  width: calc(16.66% - 1.2rem);
  margin: 0 0.6rem;
}

.customer-logo img {
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

@media screen and (max-width: 1020px) {
  .customer-logo {
    width: calc(25% - 1.2rem);
  }
}

@media screen and (max-width: 720px) {
  .customer-logo {
    width: calc(33.33% - 1.2rem);
  }
}
