.gallery {
  height: calc(100% - 4rem);
  width: 100%;
  left: 0;
  overflow-y: hidden;
  overflow-x: auto;
  /* scroll-snap-type: x proximity; */
  scroll-behavior: smooth;
  display: flex;
  flex-flow: row;
  scrollbar-width: none;
}

.snap {
  scroll-snap-type: x proximity;
}

.gallery::-webkit-scrollbar {
  display: none;
}

.vertical {
  flex-flow: column;
  align-items: center;
  height: fit-content;
}

@media screen and (max-width: 1020px) and (orientation: portrait) {
  /* .gallery {
    scroll-snap-type: x mandatory;
  } */
  .snap {
    scroll-snap-type: x mandatory;
  }
}
