@font-face {
  font-family: 'raleway';
  src: url(./assets/fonts/Raleway-VariableFont_wght.ttf);
  font-weight: 100 900;
}

* {
  box-sizing: border-box;
}

html {
  font-family: "raleway", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #e6e7e8;
  font-size: 2.5rem;
}

a {
  text-decoration: none;
  color: initial;
}

.center {
  text-align: center;
}

@media screen and (max-width: 1900px){
  body{
    font-size: 2rem;
  }
}