.socials {
  margin: auto;
  width: 42.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 1020px) {
  .socials {
    width: 100%;
  }
}
