.scroll {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 0 2rem;
}

.scroll-button {
    font-size: 2.5rem;
    cursor: pointer;
    background-color: #e6e7e8;
}