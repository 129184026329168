.welcome {
  min-height: 80vh;
}

.welcome div {
  width: 100%;
  margin: auto;
}

.welcome div h1 {
  font-size: 140px;
  margin: 0;
  line-height: 100%;
  vertical-align: baseline;
}

.welcome div p {
  line-height: 3rem;
}

@media screen and (max-width: 1020px) {
  .welcome div h1{
    font-size: 4rem;
  }

  .welcome div p {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 0 2rem;
  }
}