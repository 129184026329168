.customers {
  max-width: 60rem;
  margin: 4rem auto;
  font-size: 1rem;
}

.customer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 1020px) {
  .customers {
    margin: 2rem;
  }
  
}
